.header {
  background-color: #ffffff;
  padding: 5px 0px ;
  transition: all 0.2s;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}


.headerLeft{
  display: flex;
  align-items: center;
}

.headerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.headerSearch {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.headerSearch{
  border: 1px solid var(--color-site);
  border-radius: 5px;
  padding: 0 10px;
}

.logo {
  height: 40px;
  width: 40px;
}

.headerSearch > input {
  height: 35px;
  outline: none;
  border: none;
}

.inputContainer {
  font-size: large;
  color: #0e0c0c;

}

.inputButton {
  background-color: var(--color-site) !important;
  color: white !important;
  font-weight: 500;
}
.headerTop {
  width: 100%;
  background-color: var(--color-site);
  min-height: 40px;
}
.header-light{
  padding: 5px 0px ;
  -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  background-color: #fff;
  position: fixed;
  z-index: 1000;
  width: 100%;
  transition: all 0.2s;
  top: -10px;
  z-index: 70;
}
.header-top{
  position: fixed;
  z-index: 1000;
  width: 100%;
  transition: all 0.2s;
  top: -10px;
  z-index: 70;
}
.search-cont-items{
  min-height: 200px;
  background-color: #fdfffd;
  position: absolute;
  margin-top: 50px;
  z-index: 10000000;
  -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 20px;

}
@media all and (max-width: 759px) {
  .search-cont-items{
    width:95%;
    left: 10px;
    right: 10px;
  }
  .prodsearchtitle p{
    font-size: 0.5rem;
  }
}
@media all and (min-width: 760px) and (max-width: 1000px) {
  .search-cont-items{
    width:95%;
    left: 10px;
    right: 10px;
  }
  .prodsearchtitle p{
    font-size: 0.7rem;
  }
}
@media all and (min-width: 1001px) {
  .search-cont-items{
    width: 750px;
  }
  .prodsearchtitle p{
    font-size: 0.9rem;
  }
}
.search-items{
  min-height: 60px;
  height: auto;

  border: 1px solid rgba(63, 65, 68, 0.42);
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10000000;
}
.prodsearchtitle{
  display: flex;
  flex-direction: column;
  padding: 0px 10px;

}
.prodsearchtitle h5{
  font-size: 15px;
}
