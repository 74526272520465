:root {
    --color-site: #6c40a3;
}

body {
    background-color: #fff;
    /*font-family: "Sofia Pro", sans-serif;*/
    font-family: 'DM Sans', sans-serif;
    height: auto;
    width: auto;
}

.nav-link {
    color: #ffffff !important;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    font-size: 15px !important;
}

h3 {
    font-size: calc(1.375rem + 1.2vw);
    color: var(--color-site);
    font-family: Gilroy, sans-serif;
    font-weight: 600;
}

.sld {
    z-index: 2 !important;
}

.sld img {
    border-radius: 50%;
    height: 70px !important;
    margin-top: 10px;
}

.msg-p p {
    line-height: 16px;
}

.msg-p span {
    color: var(--color-site);
    font-weight: bold;
}

h2 {
    color: var(--color-site);
    font-family: svn-gilroy, sans-serif;
    font-weight: 600;
}

.prix-art {
    font-size: 15px;
}

.btn-cat {
    padding: 10px 10px 10px 10px;
    border: none;
    outline: none;
    background-color: var(--color-site);
    color: #fff;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-weight: bold;
}

.content {
    padding: 10px 0px 10px 20px;
}

a {
    text-decoration: none;
}

.linkItems {
    color: #fff;
    font-weight: bold;
    font-family: "Sofia Pro", sans-serif;
}

.btn-add {
    border: none;
    background-color: var(--color-site);
    outline: none;
    padding: 10px;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    color: #ffffff;
    cursor: pointer;
}

.btn-add:hover {
    border: none;
    background-color: #000;
}

a {
    color: #6c757d;
}

a:hover {
    color: #fec503;
    text-decoration: none;
}

::selection {
    background: #fec503;
    text-shadow: none;
}

footer {
    padding: 2rem 0;
    background-color: #212529;
}

.footer-column:not(:first-child) {}

@media (min-width: 768px) {
    .footer-column:not(:first-child) {
        padding-top: 0rem;
    }
}

.footer-column {}

.footer-column .nav-item .nav-link {
    padding: 0.1rem 0;
}

.footer-column .nav-item span.nav-link {
    color: #6c757d;
}

.footer-column .nav-item span.footer-title {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
}

.footer-column .nav-item .fas {
    margin-right: 0.5rem;
}

.footer-column ul {
    display: inline-block;
}

@media (min-width: 768px) {
    .footer-column ul {
        text-align: left;
    }
}

ul.social-buttons {
    margin-bottom: 0;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
    background-color: #fec503;
}

ul.social-buttons li a {
    font-size: 20px;
    line-height: 40px;
    display: block;
    width: 40px;
    height: 40px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    color: #fff;
    border-radius: 100%;
    outline: 0;
    background-color: #1a1d20;
}

footer .quick-links {
    font-size: 90%;
    line-height: 40px;
    margin-bottom: 0;
    text-transform: none;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.copyright {
    color: white;
}

.fa-ellipsis-h {
    color: white;
    padding: 2rem 0;
}

.Instagrame {
    margin-right: 10px;
}

.iconcont {
    background-color: var(--color-site);
    height: 50px;
    width: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconcont2 {
    background-color: #f1ca07;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.conteiant-site {
    min-height: 40vh;
}

.cursor-pointe {
    cursor: pointer;
}

.background {
    background: #f6f9fc;
    padding: 5px 0 1px 0;
}

.productC {
    background: #fff;
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

@media all and (max-width: 759px) {
    .productC {
        height: 250px;

    }

    .productC .img {
        padding: 20px;
    }

    .productC img {
        max-height: 230px;
    }

    .prixcontainer {
        height: 250px;
        position: absolute;
        background-color: rgba(24, 23, 23, 0.08);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 5px;


    }
}

@media all and (min-width: 760px) and (max-width: 1000px) {
    .productC {
        height: 450px;

    }

    .productC .img {
        padding: 0px 20px;
    }

    .productC img {
        max-height: 400px;
    }

    .prixcontainer {
        height: 450px;
        position: absolute;
        background-color: rgba(24, 23, 23, 0.08);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 5px;


    }
}

@media all and (min-width: 1001px) {
    .productC {
        height: 150px;

    }

    .productC .img {
        padding: 10px;
    }

    .productC img {
        max-height: 120px;
    }

    .prixcontainer {
        height: 150px;
        position: absolute;
        background-color: rgba(24, 23, 23, 0.08);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 5px;


    }
}

.productC h3 {
    font-weight: 400;
    font-size: 17px;
}

.productC .rate i {
    font-size: 15px;
    color: #ffcd4e;
    margin: 5px 5px 5px 0;
}

.productC .price {
    display: flex;
    justify-content: space-between;
    color: #e94560;
}

.price button {
    background: none;
    color: #e94560;
    font-size: 20px;
    transition: 0.5s;
    border: 1px solid rgb(3 0 71 / 9%);
    width: 35px;
    height: 35px;
    border-radius: 5px;
}

.price button:hover {
    cursor: pointer;
    background-color: #e94560;
    color: #fff;
}

.product-like {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    opacity: 0;
    transition: 0.5s;
}

.product-like label {
    background-color: #0f3460;
    padding: 1px 10px;
    font-size: 12px;
    color: white;
    border-radius: 50px;
}

.product-like i {
    font-size: 20px;
    margin: 10px 3px;
}

.productC:hover .product-like {
    opacity: 1;
}

.d_flex {
    display: flex;
    justify-content: space-between;
}

.productC h4 {
    font-weight: bold;
    font-size: 15px;
    margin-top: 01px;
    color: #ffffff;

}

.productC span {
    font-weight: normal;
    font-size: 13px;
    color: #ffffff;
}

.btn-manu {
    background-color: transparent;
    border: none;
    padding: 10px;
}

.btn-shops {
    border-color: white;
    border-radius: 50%;
    position: relative;
    z-index: 5;
    border: none;
    background-color: transparent;
    margin: 15px auto;
}

.help-but {
    color: red;
    font-weight: bold;
}

.content-terme h3 {
    font-size: 15px;
    text-align: center;
}

.content-terme h4 {
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    margin: 15px auto;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100% !important;
    height: 100% !important;
}


.btnlink {
    padding: 10px;
}

.countItemCard {
    color: #ffffff;
    background-color: #f91d14;
    padding: 1px 5px;
    border-radius: 50%;
}

.status {
    background-color: #00f912;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    position: absolute;
}

.logoManu {
    height: 150px;
    width: 150px;
    border-radius: 75px;

}

.top-buttons {
    display: flex;
    justify-content: space-between;
    margin: 25px 7px;
}

.close-btns {
    border: 0;
    background-color: transparent;
    color: red;
    font-weight: bolder;
    font-size: 20px;
}

.text-em {
    color: var(--color-site);
    font-weight: bolder;
}

.lang-btns {
    border: 0;
    background-color: transparent;
}

.lang-btns img {
    width: 22px;
}

.EngEng {
    width: 35px !important;
}

.menuMobilItem {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    border: solid 1px #000;
    padding: 10px;
    border-radius: 10px;
}

.menulist {
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 150px;
    list-style: none;
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    border-radius: 5px;
    -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    z-index: 1001;
}

.logout {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    gap: calc(0.200rem + 1vw);
}

.iconlog {
    size: calc(0.10rem + 1vw) !important;
}

.productD {
    background: #fff;
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media all and (max-width: 759px) {
    .productD {
        height: 50px;
        padding: 20px;

    }
}

@media all and (min-width: 760px) and (max-width: 1000px) {
    .productD {
        height: 180px;
        padding: 0px 20px 20px 20px;

    }
}

@media all and (min-width: 1001px) {
    .productD {
        padding: 0px 20px 20px 20px;

    }
}

.productD h3 {
    font-weight: 400;
    font-size: 17px;
}

.productD .rate i {
    font-size: 15px;
    color: #ffcd4e;
    margin: 5px 5px 5px 0;
}

.productD .price {
    display: flex;
    justify-content: space-between;
    color: #e94560;
}

.price button {
    background: none;
    color: #e94560;
    font-size: 20px;
    transition: 0.5s;
    border: 1px solid rgb(3 0 71 / 9%);
    width: 35px;
    height: 35px;
    border-radius: 5px;
}

.price button:hover {
    cursor: pointer;
    background-color: #e94560;
    color: #fff;
}

.d_flex {
    display: flex;
    justify-content: space-between;
}

.productD h4 {
    font-weight: normal;
    font-size: 15px;
    margin-top: 01px;
}

.productDs {
    background: #fff;
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media all and (max-width: 759px) {
    .productDs {
        height: 100px;
        padding: 0px 20px 1px 20px;
        cursor: pointer;

    }
}

.heading-left h5 {
    font-size: 12px !important;
}

@media all and (min-width: 760px) and (max-width: 1000px) {
    .productDs {
        height: 200px;
        padding: 0px 20px 10px 20px;
        cursor: pointer;

    }
}

@media all and (min-width: 1001px) {
    .productDs {
        padding: 20px 20px;
        height: 80px;
        cursor: pointer;

    }
}

.productDs h3 {
    font-weight: 400;
    font-size: 17px;
}

.productDs h4 {
    font-weight: normal;
    font-size: 10px;
    margin-top: 01px;
}

.bodyModal {
    min-height: 40vh;
    height: auto;
    padding: 0px 0px 20px 0px;
}

.bodyModal2 {
    min-height: 10vh;
    height: auto;
    padding: 0px 0px 20px 0px;
}

.logo2 {
    height: 150px;
    width: 150px;
    border-radius: 75px;
}

.PhoneInputCont {
    border: none;
    padding: 5px 0px;
    border-radius: 10px;
}

.PhoneInputCont2 {
    border: 1px solid #c6c1c1;
    padding: 5px;
    border-radius: 5px;
    outline: none;
}

.PhoneInputInput {}

.inputMy {
    border: none;
    outline: none;
}

.inputMy:focus,
.inputMy:hover {
    border: none;
    outline: none;
}

.PhoneInputCont2:focus-within {
    border-radius: 5px;
    outline: rgba(10, 114, 255, 0.38) 4px solid;
}

.slick-arrow {
    background-color: var(--color-site) !important;
    height: 50px !important;
    border-radius: 25px;

}

.button-bg-colr {
    background-color: var(--color-site);
    color: white;
}

.btn-bg-sec {
    background-color: rgb(17, 94, 15);
    color: white;
}

.mtSect {
    display: flex;
    flex-direction: column;
    background-color: var(--color-site);
    padding: 10px;
    border-radius: 20px;
}
















@-webkit-keyframes rotate {
    0% {}

    25% {}

    50% {}

    75% {}

    100% {}
}


.btnover1 {
    -webkit-animation-name: rotate;
    animation-name: rotate;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.btnover2 {
    -webkit-animation-name: rotate;
    animation-name: rotate;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

@media all and (min-width:1000.1px) {
    .btnover1 {
        position: fixed;
        left: 92%;
        top: 75%;
        height: 60px;
        width: 60px;
        background-color: var(--color-site);
        box-shadow: rgba(0, 0, 0, 0.38) 1px 1px 10px 0px;
        overflow: hidden;
        transition: all 0S;
        border-radius: 30px;
        z-index: 1000000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btnover2 {
        position: fixed;
        left: 93%;
        top: 55%;
        height: 60px;
        width: 60px;
        background-color: var(--color-site);
        box-shadow: rgba(0, 0, 0, 0.38) 1px 1px 10px 0px;
        overflow: hidden;
        transition: all 0S;
        border-radius: 30px;
        z-index: 1000000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media all and (max-width:1000px) {
    .btnover1 {
        position: fixed;
        left: 83%;
        top: 75%;
        height: 60px;
        width: 60px;
        background-color: var(--color-site);
        box-shadow: rgba(0, 0, 0, 0.38) 1px 1px 10px 0px;
        overflow: hidden;
        transition: all 0S;
        border-radius: 30px;
        z-index: 1000000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btnover2 {
        position: fixed;
        left: 78%;
        top: 60%;
        height: 60px;
        width: 60px;
        background-color: var(--color-site);
        box-shadow: rgba(0, 0, 0, 0.38) 1px 1px 10px 0px;
        overflow: hidden;
        transition: all 0S;
        border-radius: 30px;
        z-index: 1000000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}




.abv {
    text-align: center;
    font-size: 10px;
    font-weight: normal;
    color: #fff;
    margin-bottom: 2px;
    z-index: 9999999999999999;

}

.Demo__container {
    display: flex;

}

.Demo__some-network {
    vertical-align: top;
    display: inline-block;
    margin-right: 10px;
    text-align: center;
}

.Demo__some-network__share-count {
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
    width: 0;
    margin-top: 3px;
    font-size: 12px;
}

.Demo__some-network__share-button {
    cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
    opacity: 0.75;
}

.Demo__some-network__custom-icon {
    width: 32px;
    height: 32px;
}

.btn-partage {
    border: none;
    background-color: var(--color-site);
    height: 36px;
    width: 36px;
    border-radius: 18px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}


.checkout {
    padding-top: 80px;
    padding-bottom: 60px;
}

.checkout h6 {
    color: #999999;
    text-align: center;
    background: #f5f5f5;
    border-top: 1px solid #6AB963;
    padding: 12px 0 12px;
    margin-bottom: 75px;
}

.checkout h6 span {
    font-size: 16px;
    color: #6AB963;
    margin-right: 5px;
}

.checkout h6 a {
    text-decoration: underline;
    color: #999999;
}

.checkout__form h4 {
    color: #1c1c1c;
    font-weight: 700;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 20px;
    margin-bottom: 25px;
}

.checkout__form p {
    column-rule: #b2b2b2;
}

.checkout__input {
    margin-bottom: 24px;
}

.checkout__input p {
    color: #1c1c1c;
    margin-bottom: 20px;
}

.checkout__input p span {
    color: #dd2222;
}

.checkout__input input {
    width: 100%;
    height: 46px;
    border: 1px solid #ebebeb;
    padding-left: 20px;
    font-size: 16px;
    color: #b2b2b2;
    border-radius: 4px;
}

.checkout__input input.checkout__input__add {
    margin-bottom: 20px;
}

.checkout__input input::placeholder {
    color: #b2b2b2;
}

.checkout__input__checkbox {
    margin-bottom: 10px;
}

.checkout__input__checkbox label {
    position: relative;
    font-size: 16px;
    color: #1c1c1c;
    padding-left: 40px;
    cursor: pointer;
}

.checkout__input__checkbox label input {
    position: absolute;
    visibility: hidden;
}

.checkout__input__checkbox label input:checked~.checkmark {
    background: #7fad39;
    border-color: #7fad39;
}

.checkout__input__checkbox label input:checked~.checkmark:after {
    opacity: 1;
}

.checkout__input__checkbox label .checkmark {
    position: absolute;
    left: 0;
    top: 4px;
    height: 16px;
    width: 14px;
    border: 1px solid #a6a6a6;
    content: "";
    border-radius: 4px;
}

.checkout__input__checkbox label .checkmark:after {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 10px;
    height: 8px;
    border: solid white;
    border-width: 3px 3px 0px 0px;
    -webkit-transform: rotate(127deg);
    -ms-transform: rotate(127deg);
    transform: rotate(127deg);
    content: "";
    opacity: 0;
}

.checkout__order {
    background: #f5f5f5;
    padding: 40px;
    padding-top: 30px;
}

.checkout__order h4 {
    color: #1c1c1c;
    font-weight: 700;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.checkout__order .checkout__order__products {
    font-size: 18px;
    color: #1c1c1c;
    font-weight: 700;
    margin-bottom: 10px;
}

.checkout__order .checkout__order__products span {
    float: right;
}

.checkout__order ul {
    margin-bottom: 12px;
}

.checkout__order ul li {
    font-size: 16px;
    color: #6f6f6f;
    line-height: 40px;
    list-style: none;
}

.checkout__order ul li span {
    font-weight: 700;
    float: right;
}

.checkout__order .checkout__order__subtotal {
    font-size: 18px;
    color: #1c1c1c;
    font-weight: 700;
    border-bottom: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
    padding-bottom: 15px;
    margin-bottom: 15px;
    padding-top: 15px;
}

.checkout__order .checkout__order__subtotal span {
    float: right;
}

.checkout__order .checkout__input__checkbox label {
    padding-left: 20px;
}

.checkout__order .checkout__order__total {
    font-size: 18px;
    color: #1c1c1c;
    font-weight: 700;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 15px;
    margin-bottom: 25px;
}

.checkout__order .checkout__order__total span {
    float: right;
    color: #dd2222;
}

.checkout__order button {
    font-size: 18px;
    letter-spacing: 2px;
    width: 100%;
    margin-top: 10px;
}

.imgProf {
    width: 50px;
    height: 50px;
    border-radius: 25px;
}


.cardsl {
    background: #fff;
    margin-bottom: 30px;
    transition: .5s;
    border: 0;
    border-radius: .1875rem;
    display: inline-block;
    position: relative;
    width: 100%;
    box-shadow: none;
}

.cardsl .bodys {
    font-size: 14px;
    color: #424242;
    padding: 20px;
    font-weight: 400;
}

.profile-page .profile-header {
    position: relative
}

.profile-page .profile-header .profile-image img {
    border-radius: 50%;
    width: 160px;
    height: 160px;
    border: 3px solid #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.profile-page .profile-header .social-icon a {
    margin: 0 5px
}

.profile-page .profile-sub-header {
    min-height: 60px;
    width: 100%
}

.profile-page .profile-sub-header ul.box-list {
    display: inline-table;
    table-layout: fixed;
    width: 100%;
    background: #eee
}

.profile-page .profile-sub-header ul.box-list li {
    border-right: 1px solid #e0e0e0;
    display: table-cell;
    list-style: none
}

.profile-page .profile-sub-header ul.box-list li:last-child {
    border-right: none
}

.profile-page .profile-sub-header ul.box-list li a {
    display: block;
    padding: 15px 0;
    color: #424242
}

.profile-image {}


.cardgh {
    border: none;

    position: relative;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
}

.cardgh:before {

    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: #E1BEE7;
    transform: scaleY(1);
    transition: all 0.5s;
    transform-origin: bottom
}

.cardgh:after {

    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: #8E24AA;
    transform: scaleY(0);
    transition: all 0.5s;
    transform-origin: bottom
}

.cardgh:hover::after {
    transform: scaleY(1);
}


.fonts {
    font-size: 11px;
}

.social-list {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0;
}

.social-list li {
    padding: 10px;
    color: #8E24AA;
    font-size: 19px;
}


.buttons button:nth-child(1) {
    border: 1px solid #8E24AA !important;
    color: #8E24AA;
    height: 40px;
}

.buttons button:nth-child(1):hover {
    border: 1px solid #8E24AA !important;
    color: #fff;
    height: 40px;
    background-color: #8E24AA;
}

.buttons button:nth-child(2) {
    border: 1px solid #8E24AA !important;
    background-color: #8E24AA;
    color: #fff;
    height: 40px;
}

.activeSelectPay {
    border: solid 1px var(--color-site);
}

.collapsItem {
    background: var(--color-site);
    margin-bottom: 10px;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
}

.collapsItem2 {
    background: var(--color-site);
    margin-bottom: 0;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
}

.chilCollaps {
    border: rgba(0, 0, 0, 0.2) solid 1px;
    padding: 10px 5px 10px 10px;

}

.btn-site-Custom-blue {
    background-color: var(--color-site);
    border-radius: 0.4rem;
    color: #ffffff;
    transition: all 0.5s;
    border: solid 2px var(--color-site) !important;
    outline: none;
    padding: 0 1.8rem !important;
    line-height: 2.4571428571 !important;
    font-size: 0.9rem !important;
    font-weight: bold !important;
}

.btn-site-Custom-blue:hover {
    background-color: transparent;
    border-radius: 0.4rem;
    color: var(--color-site);
    border: solid 2px var(--color-site) !important;
    transition: all 0.5s;
}